import { CSSObject } from '@emotion/core';
import Link from 'next/link';

import { MenuTreeItem } from '@api/pages/types/menus';

import { Layout, colors, scale } from '@scripts/gds';

const linkStyles: CSSObject = {
    color: colors.textSecondGrey,
    transition: 'all .2s ease',
    ':hover': {
        color: colors.primary,
    },
};

const LinksBlock = ({ menu }: { menu?: MenuTreeItem[] | null }) => {
    return (
        <>
            {menu?.map((item, index) => (
                <Layout.Item col={{ xxxl: 4 }} key={item.name}>
                    <div itemScope itemType="http://schema.org/SiteNavigationElement">
                        <meta itemProp="name" content="Навигационное меню" />
                        {item.url ? (
                            <Link aria-label={item.name} href={item.url} passHref prefetch={false}>
                                <h3 css={{ paddingBottom: scale(4) }}>{item.name}</h3>
                            </Link>
                        ) : (
                            <h3 css={{ paddingBottom: scale(4) }}>{item.name}</h3>
                        )}

                        <ul
                            css={{
                                listStyle: 'none',
                            }}
                        >
                            {item.children.map(({ name, url }) => (
                                <li
                                    key={name}
                                    css={{
                                        ':not(:last-of-type)': {
                                            paddingBottom: scale(5, true),
                                        },
                                    }}
                                >
                                    <Link itemProp="url" legacyBehavior href={url} passHref prefetch={false}>
                                        <a itemProp="name" css={linkStyles} aria-label={name}>
                                            {name}
                                        </a>
                                    </Link>
                                </li>
                            ))}

                            {index === menu.length - 1 && (
                                <li
                                    css={{
                                        ':not(:last-of-type)': {
                                            paddingBottom: scale(5, true),
                                        },
                                    }}
                                >
                                    <a
                                        aria-label={'Личный кабинет продавца'}
                                        href="https://lk.secumarket.ru/"
                                        target="_blank"
                                        css={{
                                            ...linkStyles,

                                            color: '#27AFB0',
                                            textDecorationLine: 'underline',
                                        }}
                                    >
                                        Личный кабинет продавца
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </Layout.Item>
            ))}
        </>
    );
};

export default LinksBlock;
